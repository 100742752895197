







































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IPackage } from '@/models/packages/Package';
import {
  PackageIconColor,
  PackageIconTextColor,
  PackageIconColorLabels
} from '@/models/packages/PackageIconColor';
import { PackageIcon, PackageIconLabels } from '@/models/packages/PackageIcon';
import PackageIconComponent from '@/components/PackageIconComponent.vue';

@Component<EditPackagePage>({
  components: {
    PackageIconComponent
  }
})
export default class EditPackagePage extends Vue {
  @Prop({ default: () => [] })
  private pack!: IPackage;

  private iconColorCss = PackageIconTextColor;
  private submitted = false;
  private iconColorLabels = PackageIconColorLabels;
  private iconLabels = PackageIconLabels;
  private colorName = PackageIconColorLabels.get(this.pack.iconColor);
  private iconName = PackageIconLabels.get(this.pack.icon);
  private selectedColor = this.pack.iconColor;
  private iconColors = Object.values(PackageIconColor).filter(
    (x) => typeof x === 'number'
  );
  private icons = Object.values(PackageIcon).filter(
    (x) => typeof x === 'number'
  );

  private selectColor(selectedIndex: number) {
    this.selectedColor = selectedIndex;
    this.pack.iconColor = selectedIndex;
    this.colorName = PackageIconColorLabels.get(selectedIndex);
  }

  private selectIcon(selectedIndex: number) {
    this.pack.icon = selectedIndex;
    this.iconName = PackageIconLabels.get(selectedIndex);
  }

  private async submitPackageDetails() {
    if (!this.validForm()) {
      return;
    }
    if (this.submitted) {
      return;
    }
    await this.$store.dispatch('packageModule/updatePackageDetails', {
      packageId: this.pack.id,
      pack: this.pack
    });
    this.$router.push({
      name: 'package.detail',
      params: { id: this.pack.id.toString() }
    });
  }

  private validForm(): boolean {
    return (
      this.pack.name !== '' &&
      this.pack.family !== '' &&
      this.pack.description !== ''
    );
  }
}
