var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "container flex items-center justify-center mx-auto" },
    [
      _c("div", { staticClass: "w-3/5 pt-6 bg-white" }, [
        _c(
          "div",
          { staticClass: "flex align-bottom" },
          [
            _c("p", { staticClass: "flex-none pl-10 text-3xl" }, [
              _vm._v("Edit Package")
            ]),
            _c(
              "router-link",
              {
                staticClass: "flex justify-end w-full",
                attrs: { to: { name: "package.detail" }, active: false }
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "mt-2 mr-12 text-3xl text-gray-800",
                  attrs: { icon: ["fas", "times"] }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "form",
          {
            staticClass: "flex-col w-full pl-10",
            attrs: { onsubmit: "return false" }
          },
          [
            _vm._m(0),
            _c("div", { staticClass: "pt-2 md:w-1/3" }),
            _c("div", { staticClass: "flex-col w-11/12" }, [
              _c("p", { staticClass: "skyhub-input-field-label" }, [
                _vm._v("NAME (REQUIRED)")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pack.name,
                    expression: "pack.name"
                  }
                ],
                staticClass: "skyhub-input-field",
                attrs: { id: "inline-name", type: "text" },
                domProps: { value: _vm.pack.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.pack, "name", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "pt-6 md:w-1/3" }),
            _c("div", { staticClass: "flex-col w-11/12" }, [
              _c("p", { staticClass: "skyhub-input-field-label" }, [
                _vm._v("FAMILY")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pack.family,
                    expression: "pack.family"
                  }
                ],
                staticClass: "skyhub-input-field",
                attrs: { id: "inline-family", type: "text" },
                domProps: { value: _vm.pack.family },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.pack, "family", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "pt-6 md:w-1/3" }),
            _c("div", { staticClass: "flex-col w-11/12 h-full" }, [
              _c("p", { staticClass: "skyhub-input-field-label" }, [
                _vm._v("DESCRIPTION")
              ]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pack.description,
                    expression: "pack.description"
                  }
                ],
                staticClass: "px-4 resize-none skyhub-input-field",
                attrs: { id: "inline-description", type: "text", rows: "4" },
                domProps: { value: _vm.pack.description },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.pack, "description", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "flex w-11/12 pt-6" }, [
              _c("div", { staticClass: "w-1/2 pr-8" }, [
                _c("p", { staticClass: "uppercase skyhub-input-field-label" }, [
                  _vm._v(" package color ")
                ]),
                _c(
                  "div",
                  { staticClass: "flex skyhub-input-field" },
                  [
                    _c(
                      "select",
                      {
                        class:
                          "w-full leading-tight text-black outline-none appearance-none",
                        attrs: {
                          "data-cy": "package-color-dropdown",
                          disabled: _vm.submitted
                        },
                        domProps: { value: this.colorName },
                        on: {
                          change: function($event) {
                            return _vm.selectColor($event.target.selectedIndex)
                          }
                        }
                      },
                      _vm._l(_vm.iconColors, function(color) {
                        return _c("option", { key: color }, [
                          _vm._v(
                            " " + _vm._s(_vm.iconColorLabels.get(color)) + " "
                          )
                        ])
                      }),
                      0
                    ),
                    _c("font-awesome-icon", {
                      staticClass: "mr-4 text-2xl",
                      attrs: { icon: ["fa", "chevron-down"] }
                    })
                  ],
                  1
                ),
                _c(
                  "p",
                  { staticClass: "pt-6 uppercase skyhub-input-field-label" },
                  [_vm._v("package icon")]
                ),
                _c(
                  "div",
                  { staticClass: "flex skyhub-input-field" },
                  [
                    _c(
                      "select",
                      {
                        staticClass:
                          "w-full leading-tight text-black outline-none appearance-none",
                        attrs: {
                          "data-cy": "package-icon-dropdown",
                          disabled: _vm.submitted
                        },
                        domProps: { value: this.iconName },
                        on: {
                          change: function($event) {
                            return _vm.selectIcon($event.target.selectedIndex)
                          }
                        }
                      },
                      _vm._l(_vm.icons, function(icon) {
                        return _c("option", { key: icon }, [
                          _vm._v(" " + _vm._s(_vm.iconLabels.get(icon)) + " ")
                        ])
                      }),
                      0
                    ),
                    _c("font-awesome-icon", {
                      staticClass: "mr-4 text-2xl",
                      attrs: { icon: ["fa", "chevron-down"] }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "block w-1/2 " },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center uppercase skyhub-input-field-label"
                    },
                    [_vm._v(" preview ")]
                  ),
                  _c("package-icon-component", {
                    staticClass: "w-24 h-24 m-auto text-3xl",
                    attrs: {
                      backgroundColor: this.pack.iconColor,
                      icon: this.pack.icon,
                      packageFamily: this.pack.family,
                      "data-cy": "package-icon-component"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "w-full pt-12" }),
            _c("div", { staticClass: "flex-col w-11/12" }, [
              _c(
                "div",
                { staticClass: "flex justify-end mb-6" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: { name: "package.detail" }, active: false }
                    },
                    [_c("p", { staticClass: "mt-2 mr-8" }, [_vm._v("Cancel")])]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "skyhub-button",
                      class: { "opacity-50": !_vm.validForm() },
                      attrs: { type: "button", "data-cy": "submit-button" },
                      on: { click: _vm.submitPackageDetails }
                    },
                    [_vm._v(" Update Package ")]
                  )
                ],
                1
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-6 md:flex md:items-center" }, [
      _c("div", { staticClass: "md:w-1/3" }),
      _c("div", { staticClass: "md:w-2/3" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }